import Link from "next/link";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EventGallery } from "../../types/EventGallery";
import { Testimonial } from "../../types/Testimonial";
import { EventGalleryItem } from "../EventGalleryItem";
import { SwiperNextPurple, SwiperPrevPurple } from "../layouts/SliderArrows";
import { TestimonialItem } from "../TestimonialItem";

interface EventGalleryProps {
  event_gallery: string;
  eventGalleryList: Array<EventGallery>;
  testimonialList: Array<Testimonial>;
}

export const EventGalleryList = ({
  event_gallery,
  eventGalleryList,
  testimonialList,
}: EventGalleryProps) => {

  return (
    <div
      className="section w-full relative py-20 md:py-28 bg-purple"
      id="event-gallery"
    >
      <img
        src="/images/comp_3.svg"
        className="absolute left-0 bottom-3 md:bottom-20 w-20 md:w-auto z-40"
        alt="comp 3"
      />
      <img
        src="/images/comp_4.svg"
        className="absolute right-0 top-10 md:top-20 w-20 md:w-auto"
        alt="comp 4"
      />
      <div className="container px-5 text-center">
        <div className="md:px-36 ">
          <div className="mb-10 text-white">
            <h3 className="text-2xl md:text-4xl	font-semibold mb-5">
              Event Gallery
            </h3>
            <div
              dangerouslySetInnerHTML={{ __html: event_gallery }}
              className="text-base md:text-lg"
            ></div>
          </div>
        </div>

        <div className="relative">
          <Swiper
            modules={[Navigation]}
            slidesPerView={1}
            loop={true}
            breakpoints={{
              480: {
                slidesPerView: 2
              },
              1100: {
                slidesPerView: 3,
                
              }
            }}
            navigation={{
              prevEl: '.event-gallery-prev-button',
              nextEl: '.event-gallery-next-button'
            }}
            className="relative h-full justify-center !pr-16 md:!pr-0"
          >
            {eventGalleryList.map((data) => (
              <SwiperSlide key={data.id} className="pb-10 px-3 md:px-5"> 
                <EventGalleryItem {...data} />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* navigation arrows */} 

          <div className="event-gallery-prev-button absolute top-1/2 -translate-y-full z-20 -left-3 xl:-left-10">
            <SwiperPrevPurple />
          </div>
          <div className="event-gallery-next-button absolute top-1/2 -translate-y-full z-20 -right-3 xl:-right-10">
            <SwiperNextPurple />
          </div>
        </div>

        <div className="flex items-center justify-center mb-5">
          <Link href="/event-gallery-list">
            <div className="text-center cursor-pointer bg-purple2 text-white text-sm px-20 py-3 rounded-lg">
              View More
            </div>
          </Link>
        </div>

        <div className="md:px-36 mt-28">
          <div>
            <h3 className="text-white text-2xl md:text-4xl	font-semibold mb-5">
              What People Say About Us
            </h3>

            <div className="relative ">
              <div className="rounded-lg bg-white py-8 p-5 md:p-10">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false
                  }}
                  loop={true}
                  speed={500}
                  navigation={{
                    prevEl: '.testimonial-prev-button',
                    nextEl: '.testimonial-next-button'
                  }}
                  className="relative h-full justify-center"
                >
                  {testimonialList.map((data) => (
                    <SwiperSlide key={data.id} className="px-3 md:px-5"> 
                      <TestimonialItem key={data.id} {...data} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {/* navigation arrows */}
              <div className="testimonial-prev-button absolute top-1/2 -translate-y-1/2 z-20 -left-3 xl:-left-10">
                <SwiperPrevPurple />
              </div>
              <div className="testimonial-next-button absolute top-1/2 -translate-y-1/2 z-20 -right-3 xl:-right-10">
                <SwiperNextPurple />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
