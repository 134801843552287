import { ContactDetail } from "./ContactDetail";
import { Enquiry } from "./Enquiry";
import { FollowUs } from "./FollowUs";
import { MailingList, Theme } from "./MailingList";

export const ContactUs = () => {
  return (
    <>
      <div
        className="section w-full relative py-20 md:py-28 bg-green3"
        id="contact-us"
      >
        <div className="container px-5 md:px-20">
          <div className="grid md:grid-cols-2 gap-x-20 gap-y-20">
            <Enquiry />

            <div>
              <ContactDetail />
              <MailingList theme={Theme.Footer} />
              <FollowUs />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
