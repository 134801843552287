import Link from "next/link";
import { useEffect, useState } from "react";
import DesignTinkersService from "../../services/DesignTinkersService";
import { Event } from "../../types/Event";
import { EventSlider } from "../EventSlider";

interface IntroductionProps {
  introduction: string;
  holiday_programmes: string;
}

export const Introduction = ({
  introduction,
  holiday_programmes,
}: IntroductionProps) => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const getEvents = async () => {
      const resp = await DesignTinkersService.getEvents({
        pageSize: 6,
        page: 1,
        isLadingPage: true,
      });
      setEvents(resp.data.data);
    };

    getEvents();
  }, []);

  return (
    <div
      className="section w-full relative py-20 md:py-28"
      id="workshop-programmes"
    >
      <div className="container px-5 text-center">
        <div className="md:px-36 ">
          <div className="mb-24">
            <h3 className="text-green1 text-2xl md:text-4xl	font-semibold mb-5">
              Introduction
            </h3>

            <div
              dangerouslySetInnerHTML={{ __html: introduction }}
              className="text-base md:text-lg text-gray1"
            ></div>
          </div>
          <div className="mb-24">
            <h3 className="text-green1 text-2xl md:text-4xl	font-semibold mb-5">
              Workshop Programmes
            </h3>
            <div
              className="text-base md:text-lg text-gray1"
              dangerouslySetInnerHTML={{
                __html: holiday_programmes,
              }}
            ></div>
            <p className="text-base md:text-lg	text-gray1"></p>
          </div>
        </div>

        <EventSlider events={events} />

        <div className="flex items-center justify-center">
          <Link href="/event-list">
            <div className="cursor-pointer text-center bg-green1 text-white text-sm px-20 py-3 rounded-lg">
              View More
            </div>
          </Link>
        </div>

        <div className="mb-5 mt-20 text-green1 text-2xl font-semibold max-w-screen-lg">
          <p className="leading-normal mb-10">
            "Be the first to know of our fun & exciting workshops!"
          </p>
          <div>
            Sign up to our{" "}
            <a
              href="#mailing-list-form"
              className="underline hover:opacity-90 transition"
            >
              Mailing List
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
