import { DateTime } from "luxon";
import Image from "next/image";
import Link from "next/link";
import { News } from "../types/News";

export const NewsItem = (news: News) => {
  const happeningAt = DateTime.fromISO(news.happening_at);

  return (
    <Link href={`/news/${news.slug}`}>
      <div className="cursor-pointer shadow-lg rounded-lg overflow-hidden bg-white ">
        <div className="w-full h-44 overflow-hidden relative">
          <div className="md:hidden">
            {/* <Image
              className="h-full object-cover"
              src={news.cover_image_url.sm || ""}
              alt={news.title}
              layout="fill"
            /> */}
            <img
              className="h-full object-cover"
              src={news.cover_image_url.sm || ""}
              alt={news.title}
            />
          </div>
          <div className="hidden md:inline-flex lg:hidden">
            {/* <Image
              className="h-full object-cover"
              src={news.cover_image_url.md || ""}
              alt={news.title}
              layout="fill"
            /> */}
            <img
              className="h-full object-cover"
              src={news.cover_image_url.md || ""}
              alt={news.title}
            />
          </div>
          <div className="hidden lg:inline-flex xl:hidden">
            {/* <Image
              className="h-full object-cover"
              src={news.cover_image_url.lg || ""}
              alt={news.title}
              layout="fill"
            /> */}
            <img
              className="h-full object-cover"
              src={news.cover_image_url.lg || ""}
              alt={news.title}
            />
          </div>
          <div className="hidden xl:inline-flex">
            {/* <Image
              className="h-full object-cover"
              src={news.cover_image_url.xl || ""}
              alt={news.title}
              layout="fill"
            /> */}
            <img
              className="h-full object-cover"
              src={news.cover_image_url.xl || ""}
              alt={news.title}
            />
          </div>
        </div>
        <div className="p-6 h-56">
          <h3 className="text-purple2 text-lg md:text-xl font-semibold text__wrap2 h-14">
            {news.title}
          </h3>
          <p className="text-black text-sm my-2">
            {`${happeningAt.day} ${happeningAt.monthShort} ${happeningAt.year}`}
          </p>
          <p className="text-gray1 text-sm text__wrap3">
            {news.short_description}
          </p>
        </div>
      </div>
    </Link>
  );
};
