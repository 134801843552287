import Image from "next/image";

interface WhoWeAreProps {
  who_we_are: string;
  tessa: string;
  esther: string;
  hui: string;
}

export const WhoWeAre = ({ who_we_are, esther, tessa, hui }: WhoWeAreProps) => {
  return (
    <div className="section w-full relative py-20 md:py-28" id="who-we-are">
      <div className="container px-5 text-center">
        <div className="md:px-36 ">
          <div className="mb-10">
            <h3 className="text-purple text-2xl md:text-4xl	font-semibold mb-5">
              Who We Are
            </h3>
            <div
              className="text-base md:text-lg	text-gray1"
              dangerouslySetInnerHTML={{ __html: who_we_are }}
            ></div>
          </div>
        </div>

        <div className="text-left mt-16 md:mt-28 px-5 md:px-20">
          <h3 className="text-purple text-xl md:text-3xl	font-semibold mb-5 md:mb-10">
            Tessa Ng
          </h3>
          <div className="md:grid md:grid-cols-2 md:gap-x-20 flex flex-col-reverse">
            <div
              className="text-base md:text-lg text-gray1 mb-10"
              dangerouslySetInnerHTML={{ __html: tessa }}
            ></div>
            <div className="-ml-5 aspect-square md:ml-0 relative">
              <Image
                src="/images/foto1.png"
                className="w-auto"
                alt="tessa photo"
                layout="fill"
              />
            </div>
          </div>
        </div>

        <div className="text-left mt-16 md:mt-28 px-5 md:px-20">
          <div className="grid md:grid-cols-2 md:gap-x-20">
            <div></div>
            <h3 className="text-purple text-xl md:text-3xl	font-semibold mb-5 md:mb-10">
              Esther Wang
            </h3>
          </div>
          <div className="grid md:grid-cols-2 gap-x-20">
            <div className="relative aspect-square">
              <Image
                src="/images/foto2.png"
                className="w-auto"
                alt="esther photo"
                layout="fill"
              />
            </div>
            <div
              className="text-base md:text-lg	text-gray1 mb-10"
              dangerouslySetInnerHTML={{ __html: esther }}
            ></div>
          </div>
        </div>

        <div className="text-left mt-16 md:mt-28 px-5 md:px-20">
          <h3 className="text-purple text-xl md:text-3xl	font-semibold mb-5 md:mb-10">
            Hui Hui Ngu
          </h3>
          <div className="md:grid md:grid-cols-2 md:gap-x-20 flex flex-col-reverse">
            <div
              className="text-base md:text-lg text-gray1 mb-10"
              dangerouslySetInnerHTML={{ __html: hui }}
            ></div>
            <div className="relative aspect-square -ml-5 md:ml-0">
              <Image
                src="/images/foto3.png"
                className="w-auto"
                alt="hui photo"
                layout="fill"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
