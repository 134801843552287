import Image from "next/image";
import Link from "next/link";
import { EventGallery } from "../types/EventGallery";

export const EventGalleryItem = (eventGallery: EventGallery) => {
  return (
    <Link href={`/event-gallery/${eventGallery.slug}`}>
      <div className={`shadow-lg rounded-lg overflow-hidden bg-white cursor-pointer ${eventGallery.notSlider ? 'm-5' : ''}`}>
        <div className="w-full h-44 overflow-hidden relative">
          <div className="md:hidden">
            <Image
              src={eventGallery.cover_image_url.sm ?? ""}
              alt="cover image"
              objectFit="cover"
              layout="fill"
            />
          </div>
          <div className="hidden md:inline-flex lg:hidden">
            <Image
              src={eventGallery.cover_image_url.md ?? ""}
              alt="cover image"
              objectFit="cover"
              layout="fill"
            />
          </div>
          <div className="hidden lg:inline-flex xl:hidden">
            <Image
              src={eventGallery.cover_image_url.lg ?? ""}
              alt="cover image"
              objectFit="cover"
              layout="fill"
            />
          </div>
          <div className="hidden xl:inline-flex">
            <Image
              src={eventGallery.cover_image_url.xl ?? ""}
              alt="cover image"
              objectFit="cover"
              layout="fill"
            />
          </div>
        </div>
        <div className="h-20 p-4 flex justify-center items-center">
          <div className="flex justify-center items-center">
            <div className="text-sm p-2 flex items-center text-center">
              <p className="text-purple font-medium text__wrap2 text-center">
                {eventGallery.title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
