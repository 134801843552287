import Image from "next/image";

interface PartnershipProps {
  schools_partnerships: string;
  workshop_collaborations: string;
}

export const Partnership = ({
  schools_partnerships,
  workshop_collaborations,
}: PartnershipProps) => {
  return (
    <div className="section w-full relative py-20 md:py-28">
      <div className="container px-5 text-center">
        <div className="md:px-36 ">
          <div className="mb-10">
            <h3 className="text-purple text-2xl md:text-4xl	font-semibold mb-5">
              Partnerships + Collaborations
            </h3>
          </div>
        </div>

        <div className="text-left mt-16 md:mt-28 px-5 md:px-20">
          <h3 className="text-purple text-xl md:text-3xl	font-semibold mb-5 md:mb-10">
            Schools Partnerships
          </h3>
          <div className="grid md:grid-cols-2 gap-x-20">
            <div>
              <div
                className="text-base md:text-lg	text-gray1 mb-10 "
                dangerouslySetInnerHTML={{
                  __html: schools_partnerships,
                }}
              ></div>
            </div>
            <div className="relative aspect-[10/8] overflow-hidden rounded-lg">
              <Image
                src="/images/foto_hero2.jpg"
                objectFit="cover"
                alt="school partnership"
                layout="fill"
              />
            </div>
          </div>
        </div>

        <div className="mb-10 mt-20 px-5 md:px-20">
          <h3 className="text-purple text-xl md:text-3xl	font-semibold mb-5 md:mb-10">
            Workshop Collaborations
          </h3>
          <div
            className="text-base md:text-lg	text-gray1"
            dangerouslySetInnerHTML={{
              __html: workshop_collaborations,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
