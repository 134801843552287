import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import DesignTinkersService from "../../services/DesignTinkersService";
import { PhoneField } from "../PhoneField";

export interface EnquiryFormValues {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  selectedCountryId: number | undefined;
}

export const Enquiry = () => {
  const [isSubmitted, setIsSubmitted] = useState(0);
  const [selectedCountryId, setSelectedCountryId] = useState(193);

  const formValues = {
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
    selectedCountryId: selectedCountryId,
  };

  const postEnquiry = async (values: EnquiryFormValues) => {
    values.selectedCountryId = selectedCountryId;
    const resp = await DesignTinkersService.postEnquiry(values).catch(
      (error: unknown) => {
        setIsSubmitted(-1);
        return Promise.reject(error);
      }
    );

    setIsSubmitted(1);
  };

  return (
    <div>
      <h3 className="text-green1 text-xl md:text-3xl	font-semibold mb-5 md:mb-10">
        Enquiry
      </h3>
      {isSubmitted === 1 && (
        <div className="text-green1 font-bold">
          Thank you, your enquiry has been sent. We will reach you shortly.
        </div>
      )}
      {(isSubmitted === 0 || isSubmitted === -1) && (
        <Formik
          initialValues={formValues}
          validationSchema={Yup.object({
            name: Yup.string().max(30).required("Name is required"),
            email: Yup.string().email().max(30).required("Email is required"),
            phoneNumber: Yup.number().required("Phone number is required"),
            message: Yup.string().max(255).required("Message is required"),
          })}
          onSubmit={async (values, actions) => {
            await postEnquiry(values);
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form onChange={() => setIsSubmitted(0)}>
              <div className="mb-5">
                <p className="text-base mb-2">Your Name</p>
                <Field
                  className="rounded-md bg-white px-4 py-2 w-full"
                  type="text"
                  name="name"
                />
                {errors.name && touched.name ? (
                  <span className="text-red-500 error text-xs">
                    {errors.name}
                  </span>
                ) : null}
              </div>

              <PhoneField
                setSelectedCountryId={setSelectedCountryId}
                formValues={formValues}
              />

              <div className="mb-5">
                <p className="text-base mb-2">E-Mail</p>
                <Field
                  className="rounded-md bg-white px-4 py-2 w-full"
                  type="email"
                  name="email"
                />
                {errors.email && touched.email ? (
                  <span className="text-red-500 error text-xs">
                    {errors.email}
                  </span>
                ) : null}
              </div>
              <div className="mb-5">
                <p className="text-base mb-2">Your Message</p>
                <Field
                  as="textarea"
                  className="rounded-md bg-white px-4 py-2 w-full"
                  rows={12}
                  name="message"
                ></Field>
                {errors.message && touched.message ? (
                  <span className="text-red-500 error text-xs">
                    {errors.message}
                  </span>
                ) : null}
              </div>
              <button
                // disabled={isSubmitting}
                className="p-2 rounded-md text-center text-white bg-green1 w-full"
                type="submit"
              >
                {isSubmitting ? "SENDING" : "SEND"}
              </button>
              {isSubmitted === -1 && (
                <div className="text-red-500 font-bold">
                  Sorry, your enquiry could not be sent. Please try again.
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
