export const FollowUs = () => {
  return (
    <div>
      <h3 className="text-green1 text-sm md:text-xl	font-semibold mb-5">
        Follow us on these social media
      </h3>
      <div className="flex gap-x-10">
        <a
          className="mb-8 flex items-center"
          href="https://www.facebook.com/DesignTinkers"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/icon__fb.svg" className="w-auto mr-4" alt="" />
          <p className="text-sm md:text-base">Facebook</p>
        </a>
        <a
          className="mb-8 flex items-center"
          href="https://www.instagram.com/designtinkers"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/icon__ig.svg" className="w-auto mr-4" alt="" />
          <p className="text-sm md:text-base">Instagram</p>
        </a>
      </div>
    </div>
  );
};
