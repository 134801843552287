import { Testimonial } from "../types/Testimonial";

export const TestimonialItem = (testimonial: Testimonial) => {
  return (
    <div className="text-gray1 px-5 md:px-10 flex flex-col items-center justify-center">
      <p className="text-base">{testimonial.testimony}</p>
      <br />
      <p className="font-medium ">{testimonial.customer_name}</p>
    </div>
  );
};
