import Link from "next/link";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { News } from "../../types/News";
import { SwiperNextPurple, SwiperPrevPurple } from "../layouts/SliderArrows";
import { NewsItem } from "../NewsItem";

export const NewsList = ({ newsList }: { newsList: Array<News>}) => {

  return (
    <div className="relative w-full bg-purple overflow-hidden">
      <div
        className="section w-full relative py-20 md:py-28 "
        id="news-and-features"
      >
        <div className="container px-5">
          <div className="md:px-36 text-center">
            <div className="mb-5 md:mb-10">
              <h3 className="text-white text-2xl md:text-4xl	font-semibold">
                News & Features
              </h3>
            </div>
          </div>

          <div className="relative">
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop={true}
              speed={500}
              breakpoints={{
                480: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                1024: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                  
                }
              }}
              navigation={{
                prevEl: '.news-prev-button',
                nextEl: '.news-next-button'
              }}
              className="relative h-full justify-center !pr-16 md:!pr-0"
            >
              {newsList.map((data) => (
                <SwiperSlide key={data.id} className="pb-10 px-3 md:px-5"> 
                  <NewsItem key={data.id} {...data} />
                </SwiperSlide>
            ))}
            </Swiper>
            <div className="news-prev-button absolute top-1/2 -translate-y-full z-20 -left-3 xl:-left-10">
              <SwiperPrevPurple />
            </div>
            <div className="news-next-button absolute top-1/2 -translate-y-full z-20 -right-3 xl:-right-10">
              <SwiperNextPurple />
            </div>
          </div>

          <div className="flex items-center justify-center">
            <Link href="/news-list">
              <div className="cursor-pointer text-center bg-purple2 text-white text-sm px-20 py-3 rounded-lg">
                View More
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
