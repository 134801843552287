import type { GetServerSideProps, NextPage } from "next";
import Head from "next/head";
import { useEffect, useState } from "react";
import { ContactUs } from "../components/home/ContactUs";
import { EventGalleryList } from "../components/home/EventGalleryList";
import { HeroSection } from "../components/home/HeroSection";
import { Introduction } from "../components/home/Introduction";
import { NewsList } from "../components/home/NewsList";
import { Particles } from "../components/home/Particles";
import { Partnership } from "../components/home/Partnership";
import { WhoWeAre } from "../components/home/WhoWeAre";
import { MainLayout } from "../components/layouts/MainLayout";
import DesignTinkersService from "../services/DesignTinkersService";
import { EventGallery as EventGalleryTypes } from "../types/EventGallery";
import { MultiSizeImage } from "../types/MultiSizeImage";
import { News } from "../types/News";
import { Testimonial } from "../types/Testimonial";

interface HomeProps {
  data: {
    introduction: string;
    holiday_programmes: string;
    who_we_are: string;
    tessa: string;
    esther: string;
    hui: string;
    schools_partnerships: string;
    workshop_collaborations: string;
    event_gallery: string;
    landing_photo: MultiSizeImage;
  };
  eventGalleryList: Array<EventGalleryTypes>;
  testimonialList: Array<Testimonial>;
  newsList: Array<News>
}

const Home: NextPage<HomeProps> = (props) => {

  const [isMounted, setisMounted] = useState(false)

  useEffect(() => {
    setisMounted(true)
  }, [])

  return (
    <MainLayout>
      <Head>
        <title>DesignTinkers</title>
      </Head>
      <Particles />
      {/* section 1 - hero */}
      <HeroSection landingPhotoUrl={props.data.landing_photo} />
      {/* section 2 - introduction */}
      <Introduction
        holiday_programmes={props.data.holiday_programmes}
        introduction={props.data.introduction}
      />
      {/* section 3 - Event gallery */}
      {isMounted && (
        <EventGalleryList
          event_gallery={props.data.event_gallery}
          eventGalleryList={props.eventGalleryList}
          testimonialList={props.testimonialList}
        />
      )}
      
      {/* section 4 - who we are */}
      <WhoWeAre
        who_we_are={props.data.who_we_are}
        esther={props.data.esther}
        tessa={props.data.tessa}
        hui={props.data.hui}
      />
      {/* section 5 - news */}
      {isMounted && (
        <NewsList newsList={props.newsList} />
      )}
      {/* section 6 - partnership */}
      <Partnership
        schools_partnerships={props.data.schools_partnerships}
        workshop_collaborations={props.data.workshop_collaborations}
      />
      {/* section 6 - enquiry */}
      <ContactUs />
    </MainLayout>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const resp = await DesignTinkersService.getHomeWording();
  const data = resp.data;

  const eventGalleryResponse = await DesignTinkersService.getEventGalleryList({pageSize: 6 });
  const eventGalleryList = await eventGalleryResponse.data
  
  const testimonialResponse = await DesignTinkersService.getTestimonialList();
  const testimonialList = await testimonialResponse.data;

  const newsResponse = await DesignTinkersService.getNewsList({ pageSize: 6 });
  const newsList = await newsResponse.data

  return {
    props: {
      data,
      testimonialList,
      newsList: newsList.data,
      eventGalleryList: eventGalleryList.data
    },
  };
};
