export const ContactDetail = () => {
  return (
    <div className="mb-10">
      <h3 className="text-green1 text-xl md:text-3xl font-semibold mb-5 mt-5">
        Contact Detail
      </h3>
      <div className="mb-8 flex items-center">
        <iframe
          title="Goodman Arts Centre"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7840731215783!2d103.88218781510135!3d1.3046157990484675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1846d1cc7927%3A0x9dc99dacfa08b75c!2sGoodman%20Arts%20Centre!5e0!3m2!1sen!2sid!4v1629275984636!5m2!1sen!2sid"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </div>
      <div className="mb-8 flex items-start">
        <img src="/images/icon-map.svg" className="w-auto mr-4 mt-1" alt="address" />
        <p className="text-sm md:text-base">
          <strong className="font-bold">Goodman Arts Centre</strong> <br />
          90 Goodman Road, Singapore 439053
        </p>
      </div>
      <div className="mb-8 flex items-center">
        <img src="/images/icon-telp.svg" className="w-auto mr-4" alt="phone number" />
        <p className="text-sm md:text-base">+65 9769 1205</p>
      </div>
      <div className="mb-8 flex items-center">
        <img src="/images/icon-email.svg" className="w-auto mr-4" alt="email" />
        <p className="text-sm md:text-base">
          <a href="mailto:hello@designtinkers.sg">hello@designtinkers.sg</a>
        </p>
      </div>
    </div>
  );
};
