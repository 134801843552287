import Image from "next/image";
import { useEffectOnce } from "../../hooks/useEffectOne";
import { MultiSizeImage } from "../../types/MultiSizeImage";

interface HeroSectionProps {
  landingPhotoUrl: MultiSizeImage;
}

export const HeroSection = ({ landingPhotoUrl }: HeroSectionProps) => {
 
  useEffectOnce(() => {
    const script = document.createElement('script');

    script.src = '/js/typewriter.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    };
  });

  return (
    <div className="section h-96 md:h-screen w-full xl:bg-fixed bg-cover bg-top flex items-center relative">
      <div className="w-full h-full absolute top-0 left-0 overflow-hidden">
        <div className="md:hidden">
          <Image
            priority={true}
            src={landingPhotoUrl.sm || ""}
            layout="fill"
            objectFit="cover"
            alt="Landing photo"
          />
        </div>
        <div className="hidden md:inline-flex lg:hidden">
          <Image
            priority={true}
            src={landingPhotoUrl.md || ""}
            layout="fill"
            objectFit="cover"
            alt="Landing photo"
          />
        </div>
        <div className="hidden lg:inline-flex xl:hidden">
          <Image
            priority={true}
            src={landingPhotoUrl.lg || ""}
            layout="fill"
            objectFit="cover"
            alt="Landing photo"
          />
        </div>
        <div className="hidden xl:inline-flex">
          <Image
            priority={true}
            src={landingPhotoUrl.xl || ""}
            layout="fill"
            objectFit="cover"
            alt="Landing photo"
          />
        </div>
      </div>
      <div className="bg-purple absolute left-0 top-0 w-full h-full bg-opacity-50 z-0"></div>
      <img
        className="absolute left-0 -bottom-14 md:-bottom-40 w-28	 md:w-auto"
        src="/images/comp_1.svg"
        alt="comp 1"
      />
      <img
        className="absolute right-0 top-28 md:bottom-10 w-14	 md:w-auto"
        src="/images/comp_2.svg"
        alt="comp 2"
      />
      <div className="container px-5 z-10 ">
        <div className="xl:w-9/12">
          <h2 className="text-white text-4xl	 md:text-7xl  font-bold leading-tight md:leading-tight">
            <span
              className="txt-rotate"
              data-period="3000"
              data-rotate='[ "Empowering  <br/> Creative Thinkers", "Nurturing Design  <br/>  Confidence"]'
            ></span>
          </h2>
        </div>
      </div>
    </div>
  );
};
