import { Field, useFormikContext } from "formik";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useCountries } from "../hooks/useCountries";
import { PurchaseFormValues } from "../pages/event/[slug]";
import { Country } from "../types/Country";
import { EnquiryFormValues } from "./home/Enquiry";

export enum FormatVersion {
  Enquiry,
  Purchase,
}

interface PhoneFieldProps {
  formValues: FormValues;
  setSelectedCountryId: Function;
}

type FormValues = EnquiryFormValues | PurchaseFormValues;

export const PhoneField = ({
  formValues,
  setSelectedCountryId,
}: PhoneFieldProps) => {
  const { errors, touched } = useFormikContext<typeof formValues>();

  const [countrySearchKeyword, setCountrySearchKeyword] = useState("");
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [isCountryOptionShown, setIsCountryOptionShown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>({
    id: 193,
    name: "Singapore",
    phone_code: "+65",
    flag: "images/countries/flag/Singapore.svg",
  });
  const ref = useRef<HTMLDivElement>(null);

  const { countries } = useCountries();

  useEffect(() => {
    if (!!countries) {
      setFilteredCountries(countries);
    }
  }, [countries]);

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsCountryOptionShown(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const filter = (value: string) => {
    if (!!!countries) {
      return;
    }
    const keyword = value;

    if (keyword !== "") {
      const results = countries.filter((user) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFilteredCountries(results);
    } else {
      setFilteredCountries(countries);
    }

    setCountrySearchKeyword(keyword);
  };

  return (
    <div className="mb-5">
      <p className="text-base mb-2">Your Contact Number</p>
      <div className="relative">
        <Field
          className="rounded-md bg-white px-4 py-2 w-full pl-20"
          type="number"
          name="phoneNumber"
        />
        <span
          onClick={() => setIsCountryOptionShown(!isCountryOptionShown)}
          className="bg-green1 cursor-pointer text-white px-5 rounded-l-md absolute left-0 top-0 bottom-0 flex items-center text-sm"
        >
          {selectedCountry?.phone_code}
        </span>
        {isCountryOptionShown && (
          <div ref={ref} className="absolute bg-white w-56 mt-2 z-40">
            <div className="container sticky">
              <input
                onChange={(v) => filter(v.target.value)}
                value={countrySearchKeyword}
                type="text"
                className="border__gray3 border-solid border px-3 py-3  text-sm w-full bg-no-repeat bg__position2 "
              />
            </div>
            <ul className="w-full border__gray3 border-solid border mb-3 h-48 bg-white wrapper-country shadow-lg overflow-y-scroll">
              {filteredCountries.map((country) => (
                <li
                  key={country.id}
                  onClick={() => {
                    setIsCountryOptionShown(false);
                    setCountrySearchKeyword("");
                    setSelectedCountry(country);
                    setSelectedCountryId(country.id);
                    if (!!countries) {
                      setFilteredCountries(countries);
                    }
                  }}
                  className="mb-1 cursor-pointer element w-full hover:bg-gray-200"
                >
                  <span className="flex items-center p-3 pl-3">
                    <div className="flex-none w-6 h-full">
                      <Image
                        className="shadow-xl"
                        src={country.flag}
                        height={16}
                        width={24}
                        alt={country.name}
                      />
                    </div>
                    <span
                      className="ml-2 truncate flex-auto"
                      title={country.name}
                    >
                      {country.name}
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {errors.phoneNumber && touched.phoneNumber && (
        <span className="text-red-500 error text-xs">
          {(errors as EnquiryFormValues).phoneNumber}
        </span>
      )}
    </div>
  );
};
